/*********** GENERAL **********/

*,
*::before,
*::after {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

a {
	color: inherit;
	text-decoration: none;
}


a.logo {
	text-align: center;
	font-size: 3.125rem;
	font-weight: 800;
	margin: 3.125rem 0;
	font-family: 'Arial Rounded MT Bold', 'Helvetica Rounded', 'Nunito', Arial, sans-serif;
}

h2 {
	font-family: 'Arial Rounded MT Bold', 'Helvetica Rounded', 'Nunito', Arial, sans-serif;
	font-size: 2.5rem;
	color: #6D6D68;
}

.top-info {
	font-family: 'Gilda Display', serif;
	text-align: center;
	padding: 0.625rem 0;
	background: white;
}

.top-info a {
	color: red;
}

.page-content {
	position: absolute;
	top: 0;
	width: 100%;
	transform: translateX(-75%);
	transition: all .5s;
}

.page-content.menu-closed {
	transform: translateX(0);
}

header {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-image: url(../img/header_bg.jpg);
	background-size: cover;
	font-family: 'Lato', sans-serif;
	color: white;
	padding: 0 50px;
}

header nav {
	margin-bottom: 1.25rem;
	font-weight: bold;
}

header nav a:not(:last-child) {
	margin-right: 1.25rem;
}

header nav a.current-page {
	color: black;
}

header button {
	position: absolute;
	top: 10px;
	right: 10px;
	border: 0;
	background: none;
	padding: 0;
}

header button:hover {
	cursor: pointer;
}

header button span {
	display: block;
	height: 3px;
	width: 30px;
	background-color: white;
	margin-bottom: 5px;
}

header button span:last-child {
	margin-bottom: 0;
}

.mobile-menu {
	opacity: 1;
	position: fixed;
	top: 0;
	right: 0;
	height: 100%;
	width: calc(75% + 1px);
	background-color: black;
	color: whitesmoke;
	transition: transform .5s;
}

.mobile-menu ul {
	font-family: 'Lato', sans-serif;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	text-transform: uppercase;
}

.mobile-menu ul li {
	width: 100%;
	text-align: center;
	font-size: 22px;
	background-color: #0c0c0c;
	margin-bottom: 5px;
}

.mobile-menu ul li a {
	display: block;
	padding: 20px;
}

.mobile-menu ul li a.current-page {
	color: #ff2d2d;
}

.mobile-menu.menu-closed {
	transform: translateX(calc(100% + 1px));
}

.mobile-menu.is-hidden {
	opacity: 0;
}

.close {
	position: absolute;
	top: 10px;
	right: 10px;
	border: 0;
	background: none;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 22px;
	width: 20px;
	padding: 0;
}

.close:hover {
	cursor: pointer;
}

.close span {
	display: block;
	position: absolute;
	height: 3px;
	width: 20px;
	background-color: white;
	top: 10px;
	right: 0px;
}

.close span:nth-child(1) {
	transform: rotate(-45deg);
}

.close span:nth-child(2) {
	transform: rotate(45deg);
}

main {
	max-width: 1300px;
	margin: 50px auto;
	padding: 0 35px;
}

main.index {
	margin: -150px auto 50px auto;
}

main .about p {
	text-align: justify;
}

footer {
	font-family: 'Lato', Helvetica, Arial, sans-serif;
	background-color: #191919;
	color: rgba(255, 255, 255, .6);
	text-align: center;
	font-size: 0.75rem;
	letter-spacing: 1px;
	padding: 3.125rem;
}

footer p {
	font-weight: 300;
	line-height: 20px;
}

footer p:first-child span {
	font-weight: 700;
}

footer .copyright {
	margin-top: 0.625rem;
}

footer img {
	height: 5rem;
	margin-top: 1.2rem;
}

/********** GALLERY **********/

main.has-gallery .grid {
	display: grid;
	grid-template-columns: 1fr 1.5fr;
	gap: 2em;
	grid-template-areas: "about gallery";
}

main.has-gallery .grid img {
	width: 100%;
}

main.has-gallery .grid .about {
	grid-area: about;
	font-family: 'Gilda Display', serif;
	line-height: 1.5rem;
}

main.has-gallery .grid .about h2 {
	margin-bottom: 2rem;
}

main.has-gallery .grid .gallery {
	grid-area: gallery;
	border: 1px solid #00000030;
	padding: 2rem;
}

main.has-gallery .grid .gallery .screen {
	position: relative;
	margin-bottom: 1em;
	height: 450px;
	overflow: hidden;
}

main.has-gallery .grid .gallery .screen img:nth-child(1) {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

main.has-gallery .grid .gallery .screen .previous,
main.has-gallery .grid .gallery .screen .next {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	height: 40px;
	width: auto;
	filter: invert(1) drop-shadow(0px 0px 10px black);
}

main.has-gallery .grid .gallery .screen .previous:hover,
main.has-gallery .grid .gallery .screen .next:hover {
	cursor: pointer;
}

main.has-gallery .grid .gallery .screen .previous {
	left: 20px;
}

main.has-gallery .grid .gallery .screen .next {
	right: 20px;
}

main.has-gallery .grid .gallery .photos div {
	overflow: hidden;
}

main.has-gallery .grid .gallery .photos img {
	border-radius: 5px;
	opacity: .5;
	height: 100%;
	transition: all .2s;
}

main.has-gallery .grid .gallery .photos img:hover {
	cursor: pointer;
	opacity: 1;
}

main.has-gallery .grid .gallery .photos {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	gap: 0.5em;
}

.selected {
	opacity: 1 !important;
}

/********** MODAL **********/

.modal {
	display: none;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .8);
	transition: all .5s;
}

.modal.is-hidden {
	opacity: 0;
}

.modal .content .preview {
	height: 80vh;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
}

.modal .screen {
	height: 100%;
}

.modal .description {
	position: absolute;
	bottom: 0;
	color: white;
	left: 0;
	transform: translate(0,0);
	width: 100%;
	background-color: rgba(0, 0, 0, 0.75);
	padding: 20px;
	line-height: 20px;
	font-family: 'Lato', Helvetica, Arial, sans-serif;
}

.modal .description.is-hidden {
	display: none;
}

.modal .previous,
.modal .next {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	height: 40px;
	width: auto;
	filter: invert(1) drop-shadow(0px 0px 10px black);
}

.modal .previous {
	left: 20px;
}

.modal .next {
	right: 20px;
}

.modal .previous:hover,
.modal .next:hover {
	cursor: pointer;
}

main.has-modal .grid {
	display: grid;
	grid-template-columns: 1fr 1.5fr;
	gap: 3em;
	grid-template-areas: "about gallery";
}

main.has-modal .grid img {
	width: 100%;
}

main.has-modal .grid .about {
	grid-area: about;
	font-family: 'Gilda Display', serif;
	line-height: 1.5rem;
}

main.has-modal .grid .about h2 {
	margin-bottom: 2rem;
}

main.has-modal .grid .gallery {
	grid-area: gallery;
}

main.has-modal .grid .gallery div {
	overflow: hidden;
}

main.has-modal .grid .gallery img {
	opacity: 1;
	height: 100%;
	transition: all .2s;
}

main.has-modal .grid .gallery img:hover {
	cursor: pointer;
	opacity: .5;
}

main.has-modal .grid .gallery {
	display: grid;
	grid-template-columns: repeat(auto-fit,minmax(300px,1fr));
	gap: 0.5em;
}

/********** INDEX **********/

main.index .grid {
	display: grid;
	justify-content: center;
	grid-template-columns: 1fr 3fr;
	gap: 1em;
	grid-template-areas: "about pages";
}

main.index .grid img {
	width: 100%;
}

main.index .grid .about {
	position: relative;
	grid-area: about;
	font-family: 'Gilda Display', serif;
	line-height: 1.5rem;
	padding: 20px;
	background-color: white;
	z-index: 2;
	border: 1px solid #eaeaea;
	box-shadow: 0 0 10px #0000001f;
}

main.index .grid .about h4 {
	font-family: 'Lato', Helvetica, Arial, sans-serif;
	color: #d3ad89;
	font-weight: 300;
	text-align: center;
	margin-bottom: 1rem;
}

main.index .grid .about h2 {
	margin-bottom: 2rem;
	text-align: center;
}

main.index .grid .about ~ div {
	grid-area: pages;
	display: grid;
	grid-template-columns: repeat(2,1fr);
	grid-template-rows: repeat(2,minmax(350px,2fr));
	grid-template-areas:
		"edilizia riattazione"
		"genio forestale";
	grid-gap: 1em;
	margin-top: 170px;
}

main.index .grid .about ~ div a {
	position: relative;
	overflow: hidden;
}

main.index .grid .edilizia::after,
main.index .grid .riattazione::after,
main.index .grid .genio::after,
main.index .grid .forestale::after {
	font-family: 'Lato', sans-serif;
	font-size: 1.5em;
	font-weight: bold;
	position: absolute;
	width: 100%;
	height: 50%;
	top: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(255, 255, 255, .5);
	color: black;
	transition: all .3s cubic-bezier(0.46, 0.03, 0.38, 1.03);
}

main.index .grid .edilizia:hover::after,
main.index .grid .riattazione:hover::after,
main.index .grid .genio:hover::after,
main.index .grid .forestale:hover::after {
	top: 50%;
}

main.index .grid .edilizia::after {
	content: 'EDILIZIA';
}

main.index .grid .riattazione::after {
	content: 'RIATTAZIONE';
}

main.index .grid .genio::after {
	content: 'GENIO CIVILE';
}

main.index .grid .forestale::after {
	content: 'FORESTALE';
}

main.index .grid .edilizia {
	grid-area: edilizia;
	background-image: url(../img/index_edilizia.jpg);
	background-size: cover;
	transition: all .2s;
}

main.index .grid .riattazione {
	grid-area: riattazione;
	background-image: url(../img/index_riattazione.jpeg);
	background-size: cover;
}

main.index .grid .genio {
	grid-area: genio;
	background-image: url(../img/index_genio.jpg);
	background-size: cover;
}

main.index .grid .forestale {
	grid-area: forestale;
	background-image: url(../img/index_forestale.jpg);
	background-size: cover;
}

.banner {
	overflow: hidden;
}

.banner img {
	width: 100%;
	animation-name: banner-animation;
	animation-duration: 30s;
	animation-iteration-count: infinite;
}

@keyframes banner-animation {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1);
	}
}

/********** GENIO CIVILE **********/

/* main.genio-civile .about p:nth-child(2) {
	margin-bottom: 1em;
} */

main.genio-civile ul {
	list-style: disc;
	list-style-position: inside;
}

/********** CONTATTO **********/

main.contatto {
	display: flex;
}

main.contatto .info {
	font-family: 'Lato', sans-serif;
	width: 50%;
	margin-right: 10px;
}

main.contatto .info .apprendisti {
	display: flex;
	align-items: center;
	margin-bottom: 2rem;
}

main.contatto .info .apprendisti img {
	height: 70px;
	margin-right: 10px;
}

main.contatto .info .apprendisti p {
	font-family: 'Arial Rounded MT Bold', 'Helvetica Rounded', Arial, sans-serif;
	color: #6D6D68;
}

main.contatto .info p {
	font-family: 'Gilda Display', sans-serif;
	line-height: 20px;
}

main.contatto .info a {
	color: red;
}

main.contatto .info,
main.contatto .contact-form {
	font-family: 'Gilda Display', sans-serif;
	padding: 2rem;
	border: 1px solid #d0d0d0;
}

main.contatto h2 {
	margin-bottom: 2rem;
}

main.contatto h3 {
	font-size: 26px;
	margin-bottom: 2rem;
	font-family: 'Arial Rounded MT Bold', 'Helvetica Rounded', Arial, sans-serif;
	color: #6D6D68;
}

main.contatto .contact-form {
	width: 50%;
	margin-left: 10px;
}

main.contatto form .form-group {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
}

main.contatto label {
	display: inline-block;
	width: 150px
}

main.contatto textarea {
	height: 100px;
	min-height: 100px;
	max-height: 200px;
}

main.contatto input:not([type="submit"]), main.contatto textarea {
	height: 30px;
	border-radius: 3px;
	width: 250px;
	max-width: 250px;
	min-width: 250px;
	border: 1px solid gainsboro;
	padding: 7px;
}

main.contatto input[type="submit"] {
	margin-top: 1em;
	width: 100%;
	border: 0;
	padding: 7px;
	color: white;
	background-color: black;
	border-radius: 5px;
	font-size: 14px;
	-webkit-appearance: none;
}

main.contatto input[type="submit"]:hover {
	cursor: pointer;
}

.gotcha {
	display: none;
}

main.contatto iframe {
	width: 100%;
}

/********** MEDIAQUERIES **********/

@media screen and (max-width: 425px) {
	a.logo {
		font-size: 40px;
	}
}

@media screen and (max-width: 767px) {
	header nav {
		display: none;
	}
	.banner img {
		animation-name: none;
	}
	main.index {
		margin: -50px auto 100px auto;
	}
	main.index .grid {
		grid-template-columns: 1fr;
		grid-template-areas: 
			"about"
			"pages"
		;
	}
	main.index .grid .about ~ div {
		margin-top: 0;
		grid-template-columns: 1fr;
		grid-template-rows: repeat(4,minmax(350px,2fr));
		grid-template-areas:
		"edilizia"
		"riattazione"
		"genio"
		"forestale";
	}
	main.has-gallery .grid .gallery {
		border: 0;
		padding: 0;
	}
	main.has-gallery .grid .gallery .screen {
		display: none;
	}
	main.has-gallery .grid .gallery .photos {
		grid-template-columns: 1fr;
	}
	main.has-gallery .grid .gallery .photos img {
		border-radius: 0;
		opacity: 1;
	}
	main.contatto {
		flex-direction: column;
	}
	main.contatto .info, 
	main.contatto .contact-form {
		width: 100%;
		margin: 0;
	}
	main.contatto .info {
		margin-bottom: 20px;
	}
	.modal .content .preview {
		display: flex;
		align-items: center;
		width: 100%;
	}
	.modal .screen {
		height: auto;
		width: 100%;
	}
	.modal .description {
		display: none;
	}
}

@media screen and (max-width: 1023px) {
	.top-info {
		display: none;
	}
	main.index .grid .edilizia::after, 
	main.index .grid .riattazione::after, 
	main.index .grid .genio::after, 
	main.index .grid .forestale::after {
		top: 50%;
	}
	main.has-gallery .grid, main.has-modal .grid {
		grid-template-columns: 1fr;
		grid-template-areas: "about" "gallery";
	}
	main.contatto form .form-group {
		flex-direction: column;
	}
	main.contatto input:not([type="submit"]), main.contatto textarea {
		width: 100%;
		min-width: 100%;
		max-width: 100%;
	}
	main.contatto label {
		width: 100%;
		text-align: left;
		margin-bottom: 10px;
	}
}

@media screen and (max-width: 1200px) {
	main.index .grid .about ~ div {
		margin-top: 0;
	}
}

@media screen and (min-width: 768px) {
	header button {
		display: none;
	}
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
	main.index .grid {
		grid-template-columns: 1fr;
		grid-template-areas: 
			"about"
			"pages"
		;
	}
}

@media screen and (min-width: 1201px) and (max-width: 1400px) {
	main.index .grid {
		grid-template-columns: 1fr 2fr;
	}
}